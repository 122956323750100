.SideNavBar {
    width: 210px;
    height: auto;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    justify-content: space-between;
}

.Links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.HeaderDrop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    cursor: pointer;
}

.HeaderDrop svg {
    fill: white;
}

.link-SideNavBar{
    font-size: 24px;
    padding: 10px 0;
} 

.Seção-SideNavBar {
    padding-right: 70px;
}

.LineNavSidBar {
    background-color: white;
    height: 2px;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
    
}

.BtnLogUotSide {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background-color: #878787;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 200;
    margin-bottom: 10px;
}

@media screen and (max-width: 990px) {
    .SideOpen{
        position: absolute;
        left: 0px;
    }

    .SideClose {
        position: absolute;
        left: -210px;
    }

    .SideNavBar {
        height: 90vh;
    }  

    .opacity {
        background-color: #2b2b2bc1;
    }
}
