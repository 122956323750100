.MsgComingSoon {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.MainAceitarOr {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.MainAceitarOr button {
    width: 150px;
    height: 30px;
    margin: 20px;
}