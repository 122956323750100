.AddProd {
    width: 350px;
    border-radius: 10px;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: calc(50% - (350px/2));
    top: calc(50% - 195px);
}

.HeaderProdutos {
    min-height: 50px;
}

.AP-T {
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
}

.AP-M {
    display: flex;
    flex-direction: column;
    padding: 0 10px;

}

.AP-M div {
    display: flex;
    flex-direction: column;
    margin: 7px 0;
}

.AP-M div input {
    height: 30px;
    padding: 6px;
    border: none;
    border-radius: 5px;
    background-color: #808080;
}

.AP-M div input::placeholder {
color: #2b2b2b;
}

.AP-Btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px 0;
    height: 60px;
}

.AP-Btn button {
    height: 30px;
    width: 80px;
    border: none;
    border-radius: 2px;
    color: white;
}

.AP-Btn .Save {
    background-color: #3eb54a;
}

.AP-Btn .Cancel {
    background-color: #c04f4f;
}