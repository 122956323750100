.MainOrçamentos {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
}

.ListaFaturas {
    margin-right: 20px;
    margin-left: 20px;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
}

.ListaFaturas tr {
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.ListaFaturas td {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}

.Line_OR {
    width: 100%;
    height: 2px;
    background-color: #878787;
}


.TR-OR:hover {
    background-color: rgba(74, 74, 74, 0.302)
}

.TR_or td {
    text-align: center;
}

.TR_OR2 {
    min-height: 112px;
}

.CabecalhoFat {
    min-height: 50px;
}

.CabecalhoFat th {
    display: flex;
    align-items: center;
    justify-content: center;
}

.C_BT {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}

.CIRCLE_BTN_OR {
    background-color: white;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: 2px;
}

.BTN_OPT_OR {
    width: 50px;
    height: 50px;
    background-color: #4b4b4b68;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BTN_OPT_OR:hover {
    width: 50px;
    height: 50px;
    background-color: #9b9b9b;
    border-radius: 50%;
    z-index: 2;
    border: 2px solid #5f5f5f;
}

.OPT {
    background-color: #878787;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 12vw;
    border-radius: 10px;
    padding: 10px;
}

.OPT button {
    font-size: 22px;
    color: white;
    font-weight: 800;
}

@media screen and (max-width: 1105px) {
    .OPT {
        background-color: #878787;
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 15vw;
    }
}

@media screen and (max-width: 990px) {}

.OPT button {
    background-color: transparent;
    border: none;
    padding: 2px;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
}

.OPT button:hover {
    background-color: #757575;
    border: none;
}

.Ft {
    padding: 10px;
}

.Fade {
    z-index: 101;
}

.ConteinerPagar {
    background-color: #878787;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.HeaderPagar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    color:  white;
    font-size: 22px;
    width: 600px;
}

.TitlePgar {
    padding: 10px;
}

.TitlePgar p {
    padding-bottom: 7px;
}

.InputsPagard {
    width: 100%;
}

.InputsPagar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.InputsPagar input{
 width: 90%;
 height: 40px;
 padding: 2px;
 font-size: 20px;
}

.SelectPagar {
    padding: 10px;
}

.SelectPagar select {
    width: 45%;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
}

.FooterPagar {
    display: flex;
    justify-content: end;
    padding: 10px;
}

.FooterPagar button {
    margin: 10px;
    height: 30px;
    width: 50%;
    border-radius: 15px;
    border: none;
    color: white;
    font-size: 18px;
}

.SPRest {
    font-weight: 800;
}

.DTotalOr {
    margin-bottom: 10px;
}