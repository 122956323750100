.MainOrçamentos {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
}

.Line_OR {
    width: 100%;
    height: 2px;
    background-color: #878787;
}

.TR-OR:hover {
    background-color: rgba(74, 74, 74, 0.302)
}

.TR_or td {
    text-align: center;
}

.C_BT {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
}

.CIRCLE_BTN_OR {
    background-color: white;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    margin: 2px;
}

.BTN_OPT_OR {
    width: 50px;
    height: 50px;
    background-color: #4b4b4b68;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BTN_OPT_OR:hover {
    width: 50px;
    height: 50px;
    background-color: #9b9b9b;
    border-radius: 50%;
    z-index: 2;
    border: 2px solid #5f5f5f;
}

.OPT {
    background-color: #878787;
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 12vw;
    border-radius: 10px;
    padding: 10px;
}

.OPT button {
    font-size: 22px;
    color: white;
    font-weight: 800;
}

@media screen and (max-width: 1105px) {
    .OPT {
        background-color: #878787;
        position: absolute;
        display: flex;
        flex-direction: column;
        right: 15vw;
    }
}

@media screen and (max-width: 990px) {

}

.OPT button {
    background-color: transparent;
    border: none;
    padding: 2px;
    display: flex;
    margin-left: 5px;
    margin-right: 5px;
}

.OPT button:hover {
    background-color: #757575;
    border: none;
}