.FotosProjetos {
    padding: 5px;
    max-width: calc(95vw / 2);
}

iframe {
    margin: 10px;
    width: calc(95vw / 2);
    height: 800px;
    height: 27vw;
}

@media screen and (max-width:1180px) {
    .MainViwProjetos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .FotosProjetos {
        padding: 5px;
        max-width: 95vw;
    }

    iframe {
        margin: 10px;
        width: 95vw;
        height: 54vw;
    }
}

.sketchfab-embed-wrapper p {
    font-size: 13px;
    font-weight: normal;
    margin: 5px;
    color: #4A4A4A;
}

.sketchfab-embed-wrapper a {
    font-weight: bold;
    color: #1CAAD9;
}

.portifolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 1vw;
    margin: 20px;
}

.ImageProjeto {
    transition: 0.2s;
    max-width: calc(95vw / 3 - 25px);
    transition: .5s ease;
    backface-visibility: hidden;
}

.min-prj {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.text {
    color: white;
    font-size: 1.5vw;
}

.text-s {
    text-align: center;
    width: 100%;
    background-color: #194575;
}

@media screen and (max-width: 840px) {
    .text {
        color: white;
        font-size: 2vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 2 - 25px);
    }
}

@media screen and (max-width: 751px) {
    .text {
        color: white;
        font-size: 3vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 2 - 30px);
    }
}

@media screen and (max-width: 500px) {
    .text {
        color: white;
        font-size: 4vw;
    }
    .ImageProjeto {
        max-width: calc(95vw / 1 - 35px);
    }
}

.MainHome {
    display: flex;
    align-items: center;
}

.LineHomeTextos {
    width: 90%;
    height: 2px;
    background-color: #373737;
}

.STextosHome {
    width: 35%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 60px;
}

.ItensTextosHome {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 90%;
    padding-left: 15px;
}

.ItensTextosHome h6 {
    font-size: 16px;
    padding-bottom: 7px;
}

.SliderHome {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.itenscarrosel {
    display: flex;
}



.SImageHome {
    width: 65%;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.SImageHome img{
    max-width: 90%;
    max-height: 90%;
}

@media screen and (max-width: 1180px) {

    .itenscarrosel {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .STextosHome {
        width: 90%;
        height: calc(100vh - 90px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding-top: 30px;
        padding-bottom: 60px;
    }

    .itenscarrosel {
        display: flex;
        flex-direction: column-reverse;
    }

    .SImageHome {
        width: 100%;
        max-height: 100vw;
    }

    .SImageHome img{
        max-width: 90%;
        max-height: 90%;
    }
}

.SImageHome label,
.LabelFotos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SImageHome svg,
.LabelFotos svg {
    position: absolute;
}

.DivRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}