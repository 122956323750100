.BtnCencelUpLoad {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: #c04f4f;
    border: none;
    color: white;
}

.BtnAddRasUpLoad {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: #81d189;
    border: none;
    color: white;
}

.InputsOr {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    min-height: 1400px;
}

.InfoOr {
    background-color: #2b2b2b;
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: start;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    min-height: 350px;
}

.TituloOr {
    height: 40px;
    width: 60vw;
    border-radius: 10px;
    border: 2px solid#878787;
    color: #878787;
    background-color: #bdbdbd;
    font-size: 24px;
    font-weight: 700;
}

.SelectClientes {
    height: 40px;
    width: 35vw;
    border-radius: 10px;
    border: 2px solid#878787;
    color: #878787;
    background-color: #bdbdbd;
    font-size: 24px;
    font-weight: 700;
}

.EmailClientes {
    margin-left: 20px;
    height: 40px;
    width: calc(25vw - 20px);
    border-radius: 10px;
    border: 2px solid#878787;
    color: #878787;
    background-color: #bdbdbd;
    font-size: 24px;
    font-weight: 700;
}

.TituloOrInfo {
    font-size: 24px;
    padding-bottom: 10px;
    font-weight: 600;
}

.TOr {
    padding-top: 10px;
    padding-bottom: 15px;
    font-size: 18px;
    font-weight: 400;
}

.VencimentoOr {
    height: 40px;
    width: 20vw;
    border-radius: 10px;
    border: 2px solid#878787;
    color: #878787;
    background-color: #bdbdbd;
}

.TextIDO {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
}

.ItensOr {
    background-color: #2b2b2b;
    width: 93%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    min-height: 450px;
}

.ItensListaInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ITL {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.ITL p {
    padding-bottom: 10px;
}

.ITL input {
    width: 90%;
}

.ITL input,
.ITL select {
    height: 30px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 2px solid#878787;
    color: #878787;
    background-color: #bdbdbd;
    font-size: 16px;
    font-weight: 700;
}

.ITL select {
    width: 90%;
}

.ButtonAddNewItemOr {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 30px;
    border: none;
    border-top: 2px solid #686868;
    border-bottom: 2px solid #686868;
    background-color: #bdbdbd;
    margin-bottom: 50px;
    cursor: cell;
}

.ButtonAddNewItemOr:hover {
    opacity: .8;
}

.ButtonAddNewItemOr:active {
    opacity: .7;
}


.TotaisOr {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.SubtotalOr {
    width: 25vw;
    display: flex;
    justify-content: space-between;
}

.DescontOr {
    width: 25vw;
    display: flex;
    justify-content: space-between;
}

.DescontOrT {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.DescontOr input {
    width: 70px;
    border: none;
    background-color: #bdbdbd;
    font-size: 18px;
    font-weight: 600;
    color: #686868;
}

.DescontOr input:active {
    outline: none;
}

.DescontOr input:hover {
    outline: none;
}

.DescontOr input:focus {
    outline: none;
}

.InputDisconto {
    margin-left: 10px;
    background-color: #bdbdbd;
    color: #686868;
    border-radius: 10px;
    padding: 5px;
    border: 2px solid #686868;
}

.TIPD {
    padding-top: 15px;
}

.LineOr {
    height: 2px;
    width: 35%;
    background-color: #bdbdbd;
    margin-top: 30px;
    margin-bottom: 15px;
}

.TotalOr {
    width: 25vw;
    display: flex;
    justify-content: space-between;
}

.TotalOr p {
    font-size: 22px;
}

.TotalOr h3 {
    font-size: 26px;
    font-weight: 600;
}

.InfoAddOr {
    background-color: #2b2b2b;
    width: 93%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 30px;
    min-height: 360px;
}

.InfoAddOr h3 {
    font-size: 24px;
    font-weight: 600;
}

.InfoAddOr p {
    margin: 15px 0px 5px 0px;
}

.InfoAddOr textarea {
    background-color: #bdbdbd;
    border-radius: 10px;
    border: 2px solid #686868;
    color: #686868;
    max-width: 100%;
    min-width: 100%;
    min-height: 100px;
}

.TrashOR {
    position: absolute;
    right: 120px;
    z-index: 15;
    display: none;
}

.TrashOR button {
    width: 50px;
    height: 60px;
    border: none;
    background-color: transparent;
}

.TrashOR svg {
    width: 1vw;
    fill: #c04f4f;
}

.trashViw {
    display: block;
    z-index: 15;
}

.PreviewOrçamento {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100vw;
    z-index: 50;
    background-color: #2b2b2b80;
    max-height: 1225px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 30px;
}

.ButtonPreviw {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    margin-bottom: 30px;
}

.ButtonPreviw button {
    margin: 10px;
    width: 150px;
    height: 40px;
    border-radius: 10px;
    border: none;
}

.PreviwOr {
    z-index: 30;
    width: 210mm;
    height: 297mm;
    background-color: white;
    color: black;
    border-radius: 5px;
    position: relative;
}

.PreviewHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.PreviewHeader1 {
    display: flex;
    flex-direction: row;
    margin: 20px;
}

.Logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    overflow: hidden;
}

.LogoImg {
    min-width: 100%;
}

.PerInfo {
    margin-left: 20px;
}

.MyName {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 800;
}

.InfoPreviwOr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin-right: 20px;
}

.StatusOr {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 900;
    margin-right: 30px;
    margin-bottom: 10px;
}

.STEnviada {
    background-color: #3eb54a60;
}

.STRascunho {
    background-color: #41414160;
}

.DTitleOr {
    padding: 20px;
    font-weight: 600;
}

.InfoCl {
    margin-left: 20px;
}

.InfoClp1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.InfoClp2,
.InfoClp3,
.InfoClp4 {
    margin-bottom: 10px;
}

.HeaderOrPreços {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    background-color: #3272b659;
    border-top: 2px solid #3272b695;
    border-bottom: 2px solid #3272b695;
}

.HeaderOrPreços p {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ListPrOr {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    margin-top: 7px;
}

.Pr {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PrP {
    font-size: 16px;
    font-weight: 600;
}

.PrC {
    font-size: 14px;
    font-weight: 500;
}

.Cw {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.LinePreviewOr {
    width: 200mm;
    height: 2px;
    background-color: #6868688b;
    margin-left: 5mm;
    margin-top: 10px;
}

.TotaisPreviwOr {
    display: flex;
    justify-content: end;
}

.DTotaisPreviwOr {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}

.DTotaisPreviwOr div {
    width: 100%;
    height: 50px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DTotalOr {
    background-color: #3272b659;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
}

.MensagensPreviewOr {
    position: absolute;
    bottom: 0px;
    left: 10px;
    width: calc(210mm - 20px);
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 10px;
}

.DMensagenOr {
    margin-bottom: 10px;
}

.DMSGOR,
.DTROR {
    width: 100%;
    height: auto;
    padding: 5px;
    border: 2px solid #878787;
    border-radius: 5px;
    margin-top: 5px;
}

.SpanAddServiço {
    display: none;
    z-index: 2;
    width: 370px;
    height: 300px;
    background-color: #686868;
    position: absolute;
    left: 50%;
    bottom: calc(50% - 150px);
    transform: translate(-50%);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 3px #2b2b2b;
}

@media screen and (max-width: 965px) {
    .DescontOr input {
        font-size: 3vw;
        width: 6vw;
    }
    .PDiconto {
        font-size: 2.5vw; 
    }
    .SubtotalOr {
        width: 50%;
    }
    .DescontOr {
        width: 50%;
    }
    .TotalOr {
        width: 50%;
    }
    .LineOr {
        width: 60%;
    }
}

@media screen and (max-width: 540px) {
    .TotalOr h3 {
        font-size: 3.5vw;
    }
    .TotalOr p {
        font-size: 3.5vw;
    }
    .ITL p {
        font-size: 2.5vw;
    }
    .PDiconto {
        font-size: 2.5vw; 
    }
    .SubtotalOr {
        width: 60%;
    }
    .DescontOr {
        width: 60%;
    }
    .TotalOr {
        width: 60%;
    }
    .LineOr {
        width: 70%;
    }
    .TituloOr {
        width: 80vw;
    }
    .SelectClientes {
        width: 40vw;
    }
    .EmailClientes {
        width: calc(40vw - 20px);
    }
    .VencimentoOr {
        width: 40vw;
    }
}