.cabeçalho {
    font-size: 4vw;
    justify-content: space-between;
    height: 10vh;
    display: flex;
    flex-direction: row-reverse;
    background-color: #2b2b2b;
    overflow: hidden;
    align-items: center;
}

.meu-nome {
    margin-left: 100px;
}

.ButtonLogOutNav svg {
    fill: white;
}

img {
    margin: 10px;
    max-width: 60px;
}

.logo {
    width: 10vw;
    margin: 0 30px;
}

.marca {
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

}

.nav-list {
    list-style: none;
    display: flex;
    color: white;
    padding: 15px 32px;
}

.nav-list li {
    letter-spacing: 3px;
    margin-left: 32px;
}

.BtnOpenSideBar {
    display: none;
}

@media screen and (min-width: 1000px) {
    .cabeçalho {
        font-size: 2vw;
    }
}

@media screen and (max-width: 990px) {
    .BtnOpenSideBar {
        width: 40px;
        height: 40px;
        margin: 0 20px;
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 25px;
        left: 5px;
    }

    .SideOpen_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .SideOpen_btn_bar1 {
       display: none;
    }

    .SideOpen_btn_bar2 {
       rotate: 45deg;
    }

    .SideOpen_btn_bar3 {
       rotate: -45deg;
    }
    
    .BarNav {
        width: 100%;
        height: 2px;
        background-color: white;
    }
}