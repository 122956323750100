.Inp {
    display: flex;
    flex-direction: column;
}

.Inp input[type="text"],
.Inp input[type="date"] {
    width: 300px;
    height: 35px;
    margin: 10px 0;
    border-radius: 5px;
    border: solid 2px #454545;
    background-color: #b0b0b0;
    padding: 10px;
    color: #454545;
    font-size: 14px;
    font-weight: 800;
}

.SideLeft {
    width: 33.33333333333333%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SideMidle {
    width: 33.33333333333333%;
    height: 100%;
    padding: 30px;
}

.SideRight {
    width: 33.33333333333333%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SideMidle #Capa {
    display: none;
}

.SideMidle label div {
    width: 95%;
    aspect-ratio: 1.414/1;
    background-color: #454545;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.Inp label {
    overflow: hidden;
}

.SideMidle .Inp label {
    width: 95%;
    height: 35px;
    margin: 10px 0;
    border-radius: 5px;
    border: solid 2px #454545;
    background-color: #b0b0b0;
    color: #454545;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.SideMidle .Inp label span {
    font-size: 12px;
}

.SideMidle .Inp input[type="file"] {
    display: none;
}

.SideRight div button {
    position: absolute;
    right: 15px;
    bottom: 25px;
    width: 150px;
    height: 50px;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
    background-color: #3eb54a;
}

.LoadinSave {
    animation: Loading 1s infinite;
}

@keyframes Loading {
    to {
        transform: rotate(1turn);
    }
}

.SaveIndicator {
    width: 350px;
    height: 350px;
    background-color: #454545;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
}

.SaveIndicator .Hearde {
    font-size: 16px;
    font-weight: 600px;
    padding-bottom: 15px;
}

.SaveIndicator p {
    padding: 5px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 40px;
}

.SaveIndicator p svg {
    background-color: white;
    padding: 2px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}