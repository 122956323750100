.TaskManager-Ready {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.CardListTaskManagerReady{
    margin: 15px;
    width: 90%;
    min-height: 100px;
    border-radius: 10px;
    background-color: #3eb54a;
}

.TaskName {
    padding: 5px;
    text-align: center;
}

.LineTaskReady {
background-color: rgb(141, 255, 166);
width: 100%;
height: 2px;
}

.ReturnToInProgress {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
}

.ReturnToInProgress svg {
    fill: rgb(223, 205, 50);
}