.NavUpLoad {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.TextosNavUpLoad {
    display: flex;
}

.TxHeaderUpLoad {
    font-size: 26px;
}

.QtnArquivos {
    font-size: 22px;
    color: #d4d4d4;
}

.BtnAddUpLoad {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: #3eb54a;
    border: none;
    color: white;
}

.MainUpLoad {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
}

.SearchUpLoad {
    background-color: #2b2b2b;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px;
    border-radius: 20px;
}

.InputSearchUpLoad {
    height: 30px;
    width: 400px;
    border: none;
    font-size: 26px;
    font-weight: 600;
    color: #878787;
    background-color: #bdbdbd;
}

.Separete {
    color: #878787;
    font-size: 38px;
}

.SearchSimbol {
    fill: #878787;
}

.CardSearch {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #bdbdbd;
    width: 450px;
    border-radius: 10px;
    margin-left: 10px;
}

.SelectYear input {
    height: 50px;
    width: 100px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 24px;
    border-radius: 20px;
    border: none;
    padding-left: 17px;
}

.LineInputUpLoad {
    width: 100%;
    height: 2px;
    background-color: #878787;
}

.InputSearchUpLoad:active {
    outline: none;
}

.InputSearchUpLoad:hover {
    outline: none;
}

.InputSearchUpLoad:focus {
    outline: none;
}

.CardControlable {
    display: none;
}

.CardAddUpLoad {
    position: absolute;
    width: 45vw;
    height: 55vh;
    background-color: #686868;
    border-radius: 1.8vw;
    padding: 1.8vw;
    font-size: 24px;
    font-size: 800;
    overflow: hidden;
}

.SelectYear input{
    background-color: #bdbdbd;
    color: #878787;
}

.Fade {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #0000006e;
    width: 100Vw;
    height: 100vh;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InputUpLoad {
    display: none;
}

.LabelInputUpLoad {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7e7e7e;
}

.DivLabelInputUpLoad {
    margin: .4vw;
    background-color: #bdbdbd;
    width: 95%;
    height: 80%;
    border: 2px dashed #7e7e7e;
}

.btnpopuploads {
    margin: 1.5%;
    width: 20%;
    height: 10%;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.BtnSaveUpLoad {
    background-color: #3eb54a;
}

.BtnCancelarUpLoad {
    background-color: #c04f4f;
}

.ProgressUpLoad {
    width: 20vw;
}

.GridPreview {
    background-color: #2b2b2b;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    display: grid;
    grid-column: 2;
    grid-template-columns: auto auto auto;
}

.GridPreview svg {
fill: white;
}

.FilePreview {
    margin: 2px;
    display: flex;
    justify-content: space-between;
}

.FilePreview button {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
}

.FilePreview embed {
    width: 100%;
    height: 90%;
}

.ImagePreviw {
    max-width: 100%;
}

.LoadingUp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 90px;
}

@media screen and (max-width: 990px) {
    .GridPreview {
        display: flex;
        flex-direction: column;
    } 
}