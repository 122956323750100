.TaskManager-Canceled {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.MainTasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    min-height: 90vh;
}

.CardListTaskManagerCanceled {
    margin: 15px;
    width: 90%;
    min-height: 100px;
    height: auto;
    border-radius: 10px;
    background-color: #c04f4f;
}

.TaskName {
    padding: 5px;
    text-align: center;
}

.LineTaskCanceled {
    background-color: #ff8181;
    width: 100%;
    height: 2px;
}

.UpToInProgress {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
}

.UpToInProgress svg {
    fill: rgb(223, 205, 50);
}