.MainRedirects {
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
}

.Header_Links {
    width: 100%;
    height: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.Inputs_Links {
    padding: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    overflow: hidden;

}

.Inputs_Links div {
    display: flex;
    flex-direction: row;
}

#Link {
    width: 350px;
    margin-left: 15px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #3d3d3d;
    color: white;
    padding: 2px;
}

#Link_ENC {
    width: 250px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #3d3d3d;
    color: white;
    padding: 2px;
}

.BTN_SAVE_LINKS {
    margin: 1.5%;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
    background-color: #3eb54a;
}

.BTN_CLEAN_LINKS {
    margin: 1.5%;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
    background-color: #c04f4f;
}

.DivBTN {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.Header_Links h2 {
    font-size: 26px;
    height: 35px;
    overflow: hidden;
}

.Header_Links h2 span {
    font-size: 18px;
    padding-left: 15px;
    color: #bdbdbd;
    overflow: hidden;
}

.TitleL {
    height: 35px;
    overflow: hidden;
}