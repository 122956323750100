.CardLogin {
    width: 500px;
    height: 85vh;
    background-color: #404040;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 2px solid #737373;
    overflow: hidden;
}

.CardLogin img {
    max-width: 100px;
    align-self: center;
    padding-bottom: 30px;
}

.CardLogin input {
    height: 50px;
    padding: 10px;
    margin: 10px 0;
    font: 18px/1.5 "Segoe UI", sans-serif;
    color: #565656;
    border: 2px solid #565656;
    background-color: #b8b8b8;
    border-radius: 3px;
}

.CardLogin input:hover,
.CardLogin input:focus,
.CardLogin input::before {
    color: #565656;
    border: 2px solid #565656;
    background-color: #737373;
    border-radius: 3px;
    outline: none;
}

.CardLogin input::placeholder {
    color: #404040;
}

.CardLogin button {
    height: 40px;
    margin: 20px 0;
    color: #e5e5e5;
    background-color: #565656;
    font: 18px/1.5 "Segoe UI", sans-serif;
    border: none;
    border-radius: 2px;
    transition: background-color 0.3s;
}


.CardLogin button:hover,
.CardLogin button:active {
    background-color: #4d4d4d;
}

.CardLogin p,
.CardLogin a {
    align-self: center;
    margin: 20px;
}

.CardLogin a {
    align-self: center;
    margin: 20px;
    color: white;
    font-size: 18px;
}

.CardLogin span {
    cursor: pointer;
}

.MainLogin {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}