.TaskManager-InProgress {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
}

.CardListTaskManagerInProgress {
    margin: 15px;
    width: 90%;
    min-height: 100px;
    height: auto;
    border-radius: 10px;
    background-color: rgb(223, 205, 50);
}

.Trash svg {
    fill: white;
}

.TaskName {
    padding: 5px;
    text-align: center;
}

.LineTaskInProgress {
background-color: rgb(255, 239, 96);
width: 100%;
height: 2px;
}

.UpToReady {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
}

.UpToReady svg {
    fill: #3eb54a;
}

.btnteste {
    width: 50px;
    height: 50px;
}