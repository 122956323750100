.MainClientes {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
}

.ListaClientes {
    margin-right: 20px;
    margin-left: 20px;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow-y: visible;
    margin-bottom: 30px;
    height: auto;
}

.ListaClientes tr {
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.ListaClientes td {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
}

.LogoCliente {
    z-index: 1;
    border-radius: 50%;
}

.AddClienteCard {
    display: none;
    background-color: #2b2b2b;
    border-radius: 10px;
}

.AddClienteCard2 {
    display: none;
    background-color: #2b2b2b;
    border-radius: 10px;
}

.tabela {
    text-align: center;
    text-overflow: ellipsis;
}

.FadeAddClinte {
    display: none;
}

.FadeAddClinte2 {
    display: none;
}

.ADC {
    height: 38px;
}

.AddClienteHeader {
    width: 40vw;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-radius: 10px 10px 0 0;
    border-bottom: 3px solid #686868;
    font-size: 24px;
    font-weight: 800;
    z-index: 10;
}

.AddClienteForm {
    width: 40vw;
    display: flex;
    flex-direction: column;
    background-color: #2b2b2b;
    padding: 30px;

}

.AddClienteForm2 {
    width: 40vw;
    display: flex;
    flex-direction: column;
    background-color: #2b2b2b;
    padding: 30px;

}

.TX-CL {
    width: auto;
    margin-top: 10px;
    margin-bottom: 3px;
}


.AddClienteEndRua,
.AddClienteEndBairro,
.AddClienteEndNumero {
    width: 33.33333333333333%;
}

.AddClienteEndTipo {
    width: 50%;
}

.AddClienteSubNome,
.AddClienteNome {
    width: 100%;
}

.InpBNome {
    width: 100%;
}

.FotoPerfilCliente {
    margin-right: 20px;
    margin-left: 20px;
}

.InpNome {
    display: flex;
    justify-content: space-between;
}

.AddClienteEmail,
.AddClienteEti,
.AddClienteEndComp {
    width: 100%;
}

.AddClienteEndCidade,
.AddClienteEndCep,
.AddClienteEndEstado {
    width: 33.33333333333333%;
}

.AddClienteCellTipo {
    width: 23%;
}

.AddClienteCellCod {
    width: 15%;
}

.AddClienteCellNum {
    width: 72%;
}


.AddClienteEmail,
.AddClienteSubNome,
.AddClienteCellNum,
.AddClienteEndCidade,
.AddClienteEndCep,
.AddClienteEti,
.AddClienteEndEstado {
    border-radius: 10px;
    border: 1px solid #878787;
    color: #878787;
    font-weight: 600;
    background-color: #bdbdbd;
    margin-bottom: 15px;
}

.AddClienteNome,
.AddClienteCellTipo,
.AddClienteCellCod,
.AddClienteEndTipo,
.AddClienteEndRua,
.AddClienteEndComp,
.AddClienteEndBairro,
.AddClienteEndNumero {
    border-radius: 10px;
    border: 1px solid #878787;
    color: #878787;
    font-weight: 600;
    background-color: #bdbdbd;
    margin-bottom: 5px;
}

.AddClienteFoot {
    border-top: 3px solid #686868;
    height: 70px;
    display: flex;
    justify-content: end;
    padding: 15px;
}

.BtnSalvarCliente {
    background-color: #3eb54a;
    margin-right: 20px;
    width: 12vw;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.BtnCancelarSalvarCliente {
    background-color: #c04f4f;
    width: 12vw;
    border-radius: 10px;
    border: none;
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.TelefoneAddCl {
    display: flex;
}

.AddClienteForm input::placeholder {
    color: #878787;
}

.AddClienteForm input {
    padding-left: 10px;
    font-size: 18px;
}

.Loading {
    animation: loading 2s cubic-bezier(0, .25, .7125, .1) infinite;
}

@keyframes loading {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.ShowEdit {
    display: block;
    z-index: 5;
}

.EitarLogo {
    box-shadow: 0 0 15px 5px #2b2b2b;
    background-color: #878787;
    width: 30vw;
    height: 20vw;
    position: absolute;
    top: 50%;
    left: calc(50% - 15vw);
    transform: translateY(-50%);
    z-index: 2;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EitarLogo {
    display: none;
}

.EitarLogo input {
    display: none;
}

.EitarLogo label {
    color: #929292;
    background-color: rgb(241, 241, 241);
    width: 25vw;
    height: 15vw;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #929292;
}

.DBTNEDCL {
    margin-top: 10px;
}

.TdImgC {
    width: 50%;
}

.TdEmailC {
    width: 160%;
}

.TdNameC {
    width: 85%;
}

.TdOptC {
    width: 35%;
}

@media screen and (max-width: 990px) {
    .ListaClientes {
        margin-right: 0px;
        margin-left: 0px;
    }

    .AddClienteHeader {
        width: 100vw;
    }

    .AddClienteForm {
        width: 100vw;
    }

    .AddClienteForm input {
        font-size: 3vw;
    }

    .InpBNome {
        width: 85%;
    }

    .BtnSalvarCliente {
        width: 50%;
    }

    .BtnCancelarSalvarCliente {
        width: 50%;
    }
}

@media screen and (max-width: 648px) {
    .tabela td {
        font-size: 2.2vw;
    }

    .TdOptC {
        width: 50%;
    }
}

.ImgPerfil {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.261);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
}

.ImgPerfil img {
    min-width: 500px;
}

.ImgPerfil p {
    cursor: pointer;
}

@media screen and (max-width: 514px) {
    .ImgPerfil img {
        min-width: 100vw;
    }
}

.Centred {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2b2b2b58;
    z-index: 300;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Situação {
    padding: 20px;
    background-color: #2b2b2b;
}

.TitleSit {
    padding-bottom: 5px;
}

.FatABSIT {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.NomeFatSit {
    padding-left: 15px;
}

.VSit {
    padding-left: 15px;
    padding-bottom: 10px;
}

.VTPSite {
    padding-bottom: 10px;
}

.VSIT {
    padding-bottom: 15px;
}

.Centred div {
    background-color: #2b2b2b;
    padding: 15px;
    box-shadow: 0px 0px 18px 9px #676767;
}

.Centred div button {
    width: 100%;
    align-self: center;
    color: #2b2b2b;
    border: none;
    border-radius: 5px;
    height: 30px;
    font-weight: 600;
}

.BTNDANW {
position: fixed;
top: 15px;
left: 10px;
}