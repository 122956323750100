/* header projeto */
.MainProjetos {
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
}

.TxHeaderProjetos {
    font-size: 26px;
}

.HeaderProjetos {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.QtnProjetos {
    font-size: 22px;
    color: #d4d4d4;
}

.BtnAddProjeto {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 900;
    background-color: #3eb54a;
    border: none;
    color: white;
}

.fa-plus-pj {
    padding: 0 10px;
}

.BtnAddProjeto:hover {
    cursor: pointer;
    box-shadow: 10px 10px 10px 1px #2b2b2b33;
}

.BtnAddProjeto:active {
    opacity: .8;
}

/* header projeto */

.ListaDeProjetos {
    margin-right: 20px;
    margin-left: 20px;
    background-color: #2b2b2b;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow-y: visible;
    margin-bottom: 30px;
}

.ListaDeProjetos tr {
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.dia-add-pj {
    box-shadow: 0px 0px 20px 8px #2b2b2b;
    background-color: #bdbdbd;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50%;
    transform: translatey(-50%);
    display: flex;
    flex-direction: column;
}

.SecundMain{
    display: flex;
    flex-direction: row;
}

.ButtonOptionsProjetos {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}   

.circle {
    background-color: white;
    width: 8px;
    height: 8px;
    margin: 3px;
    border-radius: 50%;
    box-shadow: 0 0px 5px 2px #2b2b2b;
}

.ImagemPreviw {
    max-width: 200px;
    background-color: white;
}

.NameProjeto {
    font-size: 24px;
}

.ThOpProjetos,
.TdOpProjetos {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.ThTProjetos,
.TdTProjetos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ThCProjetos,
.TdCProjetos {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 573px) {
    .cardListaProjetos img {
        width: 20vw;
    }
}

@media screen and (max-width: 480px) {
    .NameProjeto {
        font-size: 4vw;
    }
}