.MainComfigurações {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
}

.LinhaComfig {
    width: 95%;
    height: 2px;
    background-color: #2b2b2b;
    align-self: center;
    margin-bottom: 20px;
}

.MainComfigurações section h2 {
    font-size: 32px;
}

.MainComfigurações table {
    width: 95%;
    align-self: center;
    padding: 10px;
}

.MainComfigurações table thead tr th {
    font-size: 24px;
}

.MainComfigurações table tbody tr td {
    font-size: 18px;
}

.MainComfigurações table tbody tr td input {
    height: 30px;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #2b2b2b;
    background-color: transparent;
}

.MainComfigurações table tbody tr td input:focus {
    outline: none;
}

.MainComfigurações table tbody tr td input::selection{
    color: #878787;
    background-color: #2b2b2b;
}

.VarialComfig {
    display: flex;
    align-items: center;
    justify-content: end;
}

.BtnSalvar {
    width: 100px;
    height: 60px;
    font-size: 20px;
    font-weight: 600;
    background-color: #3eb54a;
    border: none;
    border-radius: 5px;
    color: white;
    transition: 3ms;
}

.BtnSalvar:disabled {
    background-color: #2e2e2e;
}

.BtnSalvar:hover {
    filter: brightness(85%);
}

.BtnSalvar:active {
    filter: brightness(70%);
}

.GEO {
    background-color: red;
}

.BIO {
    background-color: aqua;
}

.TRILHA {
    background-color: rgb(196, 222, 48);
}

.PÓS_M {
    background-color: blue;
}

.ELETIVA {
    background-color: blueviolet;
}

.QUIM {
    background-color: chocolate;
}

.HIST {
    background-color: cornflowerblue;
}

.PORT {
    background-color: darksalmon;
}

.FIS {
    background-color: lightgreen;
}

.SOC {
    background-color: darkmagenta;
}

.ES_ORI {
    background-color: deeppink;
}

.EF {
    background-color: crimson;
}

.MAT {
    background-color: darkgoldenrod;
}

.FILO {
    background-color: darkgreen;
}

.PV {
    background-color: fuchsia;
}

.ARTES {
    background-color: darkblue;
}

.MainComfigurações table tbody tr td select {
    height: 30px;
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    margin-right: 5px;
    border: none;
    color: rgb(0, 0, 0);
}