@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #686868;
  color: white;
  font-family: Inter, arial;
}


/* nice scroll bar */
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #686868;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: #2b2b2b;
  border-radius: 50px;
}

html {
  overflow-y: hidden;
}

main {
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
}

a {
  color: white;
}

* {
  font-family: Inter, arial;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  overflow-x: hidden;
  font-family: 'Inter', Arial;
}

code {
  font-family: Inter, arial;
  overflow: hidden;
}


/* btns Task Manager */
.CreatAt {
  height: 30px;
  display: flex;
  align-items: center;
}

.Term {
  height: 30px;
  display: flex;
  align-items: center;
}

.BtnsTaskManager {
  font-size: 13px;
  font-weight: 900;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.Trash {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
}
/* btns Task Manager */

.react-confirm-alert {
  z-index: 1000000;
}