.BtnReadyTaskManager {
    min-height: 60px;
    width: 110%;
    background-color: #3eb54a;
}

.BtnReadyTaskManager a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.BtnInprogressTaskManager {
    min-height: 60px;
    width: 140%;
    background-color: rgb(223, 205, 50);
    display: flex;
    align-items: center;
    justify-content: center;
}

.BtnInprogressTaskManager a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.BtnCanceledTaskManager {
    min-height: 60px;
    width: 120%;
    background-color: #c04f4f;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BtnCanceledTaskManager a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NavTaskManager {
    display: flex;
    justify-content: space-around;
    width: 100%;
    min-height: 60px;
}

.btn-active-tm {
    height: 50px;
}

.LineT {
    width: 2px;
    height: 100%;
    background-color: white;
}

.ButtonAddTask {
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: row;
}

.ButtonAddTaskbtn {
    width: 100%;
    border: none;
    color: white;
    overflow: hidden;
    background-color: #3eb54a;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Bar01Tasks {
    border-radius: 5px;
    width: 10px;
    height: 40px;
    background-color: white;
}

.Bar02Tasks {
    border-radius: 5px;
    width: 40px;
    height: 10px;
    background-color: white;
}

.ButtonAddTaskbtn:hover {
    opacity: .8;
}

.ButtonAddTaskbtn:active {
    opacity: .9;
}

.Fadetask {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
}

.PopUpAddTask {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #878787;
    padding: 20px;
    border-radius: 10px;
}

.PopUpAddTask section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.InputsTimeTasks {
    display: flex;
}

.InputTimeTasks,
.inputDate,
.IputName {
    display: flex;
    flex-direction: column;
}

.datainput,
.terminput {
    padding: 10px;
    margin: 10px;
    width: 15vw;
}

.taskinput {
    padding: 10px;
    margin: 10px;
    width: calc(30vw + 20px);
}

.Fadetask progress {
    width: calc(30vw + 20px);
    margin-bottom: 10px;
}

.BtnSaveTask,
.BtnCancelarTask {
    width: 150px;
    height: 50px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 24px;
}

.BtnSaveTask {
    background-color: #3eb54a;
}

.BtnCancelarTask {
    background-color: #C04F4F;
}

@media screen and (max-width: 990px) {
    .PopUpAddTask {
        padding: 20px;
    }
    .datainput,
    .terminput {
        width: 40vw;
    }
    
    .taskinput {
        width: calc(80vw + 20px);
    }
    
    .Fadetask progress {
        width: calc(80vw + 20px);
    }
}