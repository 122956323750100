.MainAjenda {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 90vh;
    justify-content: space-around;
    /* overflow-y: scroll; */
}

:root {
    --primary-clr: #2b2b2b;
}

.ConteinerCalender {
    max-width: 700px;
    height: 100%;
}

.ConteinerListTasksDay {
    max-width: 650px;
    height: 100%;
    padding: 20px 0;
}

.ConteinerCalender .month {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
}

.ConteinerCalender .month .prev,
.ConteinerCalender .month .next {
    cursor: pointer;
}

.ConteinerCalender .month .prev:hover,
.ConteinerCalender .month .next:hover {
    color: var(--primary-clr);
}

.ConteinerCalender .weekdays {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
}

.weekdays div {
    width: 14.28%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* dias */
.ConteinerCalender .days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.ConteinerCalender .days .day {
    width: 14.28%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-clr);
    border: 1px solid #f5f5f5;
}

.ConteinerCalender .days .day:nth-child(7n + 1) {
    border-left: 2px solid #f5f5f5;
}

.ConteinerCalender .days .day:nth-child(7n) {
    border-ConteinerListTasksDay: 2px solid #f5f5f5;
}

.ConteinerCalender .days .day:nth-child(-n + 7) {
    border-top: 2px solid #f5f5f5;
}

.ConteinerCalender .days .day:nth-child(n + 29) {
    border-bottom: 2px solid #f5f5f5;
}

.ConteinerCalender .days .day:not(.prev-date, .next-date):hover {
    color: #fff;
    background-color: var(--primary-clr);
}

.ConteinerCalender .days .prev-date,
.ConteinerCalender .days .next-date {
    color: #b3b3b3;
}

.ConteinerCalender .days .active {
    position: relative;
    font-size: 2rem;
    color: #fff;
    background-color: var(--primary-clr);
}

.ConteinerCalender .days .active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px 2px var(--primary-clr);
}

.ConteinerCalender .days .today {
    font-size: 2rem;
}

.ConteinerCalender .days .event {
    position: relative;
}

/* Y */
.ConteinerCalender .days .event_Y::after {
    content: "";
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 75%;
    height: 6px;
    border-radius: 30px;
    transform: translateX(-50%);
    background-color: rgb(223, 205, 50);
}

.ConteinerCalender .days .day:hover.event_Y::after {
    background-color: rgb(223, 205, 50);
}

.ConteinerCalender .days .active.event_Y::after {
    background-color: rgb(223, 205, 50);
    bottom: 20%;
}

/* Y */

/* R */
.ConteinerCalender .days .event_R::after {
    content: "";
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 75%;
    height: 6px;
    border-radius: 30px;
    transform: translateX(-50%);
    background-color: #3eb54a;
}

.ConteinerCalender .days .day:hover.event_R::after {
    background-color: #3eb54a;
}

.ConteinerCalender .days .active.event_R::after {
    background-color: #3eb54a;
    bottom: 20%;
}

/* R */

/* C */
.ConteinerCalender .days .event_C::after {
    content: "";
    position: absolute;
    bottom: 10%;
    left: 50%;
    width: 75%;
    height: 6px;
    border-radius: 30px;
    transform: translateX(-50%);
    background-color: #c04f4f;
}

.ConteinerCalender .days .day:hover.event_C::after {
    background-color: #c04f4f;
}

.ConteinerCalender .days .active.event_C::after {
    background-color: #c04f4f;
    bottom: 20%;
}

/* C */

.ConteinerCalender .days .active.event {
    padding-bottom: 10px;
}

/* dias */

.ConteinerCalender .goto-today {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 0 20px;
    margin-bottom: 20px;
    color: var(--primary-clr);
}

.ConteinerCalender .goto-today .goto {
    display: flex;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid var(--primary-clr);
}

.ConteinerCalender .goto-today .goto input {
    width: 100%;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 20px;
    color: var(--primary-clr);
    border-radius: 5px;
    background-color: transparent;
}

.ConteinerCalender .goto-today .goto input::placeholder {
    color: var(--primary-clr);
}

.ConteinerCalender .goto-today button {
    padding: 5px 10px;
    border: 1px solid var(--primary-clr);
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    color: var(--primary-clr);
}

.ConteinerCalender .goto-today button:hover {
    color: #fff;
    background-color: var(--primary-clr);
}

.ConteinerCalender .goto-today .goto button {
    border: none;
    border-left: 1px solid var(--primary-clr);
    border-radius: 0;
}

.add-event {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #878895;
    border: 2px solid #878895;
    opacity: 0.5;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
}

.add-event:hover {
    opacity: 1;
}

.add-event i {
    pointer-events: none;
}

.today-date {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.DataCompleta {
    display: flex;
}

.DataCompleta p {
    padding: 10px;
}

.DiaSemana {
    padding-right: 40px;
    font-size: 2rem;
    font-weight: 500;
}

.Ind {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.Ind_Y {
    background-color: rgb(223, 205, 50);
}

.Ind_R {
    background-color: #3eb54a;
}

.Ind_C {
    background-color: #c04f4f;
}

.Event {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(90deg, #87878750, transparent);
}

.Hover_Y:hover {
    background: linear-gradient(90deg, rgba(223, 206, 50, 0.25), transparent);
}

.Hover_R:hover {
    background: linear-gradient(90deg, #3eb54a25, transparent);
}

.Hover_C:hover {
    background: linear-gradient(90deg, #c04f4f25, transparent);
}

.DivInd {
    padding: 20px;
}

@media screen and (max-width: 1297px) {
    .today-date {
        height: 80px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1147px) {
    .MainAjenda {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ConteinerCalender {
        max-width: 700px;
        min-height: 1325px;
        display: flex;
        flex-direction: column;
        justify-content: end;
    }
    .ConteinerListTasksDay {
        min-height: 500px;
    }
    .ConteinerListTasksDay {
        width: 100%;
    }
}