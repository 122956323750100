.game {
    width: 100vw;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 24px;
}

.game-board {
    grid-template-columns: repeat(3, 100px);
    grid-gap: 2px;
    width: 95%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.square {
    width: 123.3333333333333px;
    height: 123.3333333333333px;
    border: none;
}

.board-row {
    display: flex;
}

.square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    font-size: 36px;
    font-weight: bold;
    background: #fff;
    border: 1px solid #878787;
    cursor: pointer;
}

.status {
    margin-top: 50px;
}

.Vline {
    width: 2px;
    height: 100%;
    background-color: black;
}

.Hline {
    width: 100%;
    height: 2px;
    background-color: black;
}

.Reset {
    width: 150px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background-color: #c04f4f;
    color: white;
    font-size: 20px;
    font-weight: 600;
    position: fixed;
    bottom: 20px;
}

.Clean {
    width: 150px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background-color: #3eb54a;
    color: white;
    font-size: 20px;
    font-weight: 600;
    position: fixed;
    bottom: 60px;
}


.scoreboard {
    text-align: center;
    margin-top: 20px;
}

.player-score {
    font-size: 20px;
    margin: 10px;
}

.player-score p {
    margin: 0;
}